import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/packages/recipes/src/layouts/recipe.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h5>{`Prep time: 45 min. `}<meta itemProp="prepTime" content="PT45M" /></h5>
    <h5>{`Cook time: 45 min. `}<meta itemProp="cookTime" content="PT45M" /></h5>
    <h5>{`Servings: `}<span itemProp="recipeYield">{`2`}</span></h5>
    <hr></hr>
    <h2>{`Ingredients`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Ground beef, 1/2 lbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Mayo, 2 tbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Sriracha, 1 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">
  4 cheese slices (Cabot New York Extra Sharp Cheddar)
        </span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Eggs, 2</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Butter, 1 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Pineapple slices, 2 slices</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Sliced beets, for topping</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Tomato, sliced for topping</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Bread slices or hamburger buns</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Equipment`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="tool">Frying pan</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Toaster</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Prep`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="step">Shape ground beef into 2 patties</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Slice cheese</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Slice tomato</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Prep spicy mayo: mix mayo and sriracha</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Instructions`}</h2>
    <span itemProp="recipeInstructions">
      <ol>
        <li parentName="ol">
          <p parentName="li">{`Heat pan on high, add butter, then pan fry beef patties, approximately 3-5 minutes per side. Add cheese slices after flipping, while the second side is cooking. Set aside.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`While the beef patties are nearly done, start toasting the bread.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Fry pineapple slices until each side is a little browned. Set aside.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Fry eggs, sunny side up.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`While the pineapple and eggs are cooking, start arranging burgers: spread spicy mayo on the bread slices, add tomato slices, beef patties, pineapple slices, beet slices, and the fried eggs once they're done.`}</p>
        </li>
      </ol>
    </span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      